import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ReDialog from 'components/ReDialog';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import localStorageNames from 'data/localStorageNames';
import { useCartContext } from 'context/CartContext';
import { useAuthContext } from 'context/AuthContext';
import Button from 'components/Button';
import { ErrorOutlineRounded } from '@material-ui/icons';

const useStyles = makeStyles({
  titleStyle: {
    paddingBottom: 0,
  },
  customButton: {
    marginTop: 20,
    fontWeight: 'bold',
    width: '80%',
    left: `calc(50% - 80%/2)`,
  },
  confirmText: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
});

const QrCodeDialog = ({ closeModal, data = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getSetting, getColorApp } = useAuthContext();
  const { clearCart } = useCartContext();
  const [open, setOpen] = useState(false);
  const [tableName, setTableName] = useState(null);


  const handleIfCloseTab = () => {
    window.addEventListener('beforeunload', alertUser);
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleClose = () => {
    setOpen(false);
    closeModal();
  };

  useEffect(() => {
    const setting = JSON.parse(localStorage.getItem(localStorageNames.SETTING));
    if (setting.tableName) {
      setTableName(setting.tableName);
    }
  }, []);

  useEffect(() => {
    handleIfCloseTab();
    clearCart();

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line
  }, []);

  const AlertDialog = () => {
    return (
      <ReDialog
        title={
          <ErrorOutlineRounded
            style={{ color: theme.palette.success.main, fontSize: '3rem' }}
          />
        }
        titleStyle={classes.titleStyle}
        customTitleStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
        contentStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
        content={
          <Grid container direction="column" spacing={8}>
            <Grid item>
              <Typography className={classes.confirmText} style={{ color: getColorApp()?.primaryFontColor }}>
                Are you sure you want to leave this page?
              </Typography>
            </Grid>
            <Grid container direction="row">
              <Grid item style={{ width: '50%' }}>
                <Button
                  onClick={() => setOpen(false)}
                  className={classes.customButton}
                  size="large"
                  type="cancel"
                  title={`${t('cancel')}`}
                />
              </Grid>
              <Grid item style={{ width: '50%' }}>
                <Button
                  onClick={handleClose}
                  className={classes.customButton}
                  size="large"
                  type="confirm"
                  title={`${t('confirm')}`}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );
  };

  return (
    <ReDialog
      fullScreen={true}
      closeModal={closeModal}
      useCloseButton={false}
      contentStyle={{ paddingTop: theme.spacing(-10), backgroundColor: 'white' }}
      content={
        <Box
          display="flex"
          flex="4"
          flexDirection="column"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh',
          }}>
          {open && (<AlertDialog />)}
          <Prompt
            when={true}
            message={(location, action) => {
              if (action === 'POP') {
                setOpen(true);
                return false;
              }
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" style={{ marginBottom: theme.spacing(4) }}>
              {tableName && `TABLE : ${tableName}`}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {
                (getSetting() && getSetting().qrcodeTitlePage) ?
                  getSetting().qrcodeTitlePage :
                  t('qrcodeTitle')
              }
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
              {t('qrcodeSubTitle')}
            </Typography>
            <QRCode value={data} size="300" />
            <Typography variant="h5" style={{ marginTop: theme.spacing(4) }}>
              {data}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default QrCodeDialog;
