import { Button } from '@material-ui/core';
import React from 'react';
import theme from 'styles/theme';

const CustomButton = (props) => {
  let style = {};

  let yesButton = {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  };

  let cancelButton = {
    backgroundColor: 'lightgray',
    color: 'gray',
  };

  switch (props.type) {
    case 'confirm':
      style = yesButton;
      break;
    case 'cancel':
      style = cancelButton;
      break;
    default:
      break;
  }

  return (
    <div>
      <Button
        className={props.className}
        onClick={() => {
          props.onClick();
        }}
        variant="contained"
        disable={props.disable}
        size={props.size}
        style={style}>
        {props.title}
      </Button>
    </div>
  );
};

export default CustomButton;
