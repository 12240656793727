import {
  Box,
  ButtonBase,
  Dialog,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import LoadingModal from 'components/LoadingModal';
import { useAuthContext } from 'context/AuthContext';

const stripePromise = loadStripe(
  'pk_test_51K64guJQPZPAX4eKKBYzUDson3BR5iW8LRs5R1s1jaEl98Hg7h5PaoNJ7ZKmGiEWGyjwVOfTD6zBMNcRM8DUBRvI00c694iHEw',
);

const useStyles = makeStyles({
  paper: {
    width: `95% !important`,
    marginLeft: 0,
    marginRight: 0,
    overflowY: 'visible',
  },
  modalFullScreen: {
    width: '100% !important',
  },
  limitSize: {
    maxWidth: `${theme.containerWidth}px !important`,
  },
  paperWidthFalse: {
    maxWidth: '100%',
  },
  innerTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  buttonConfirm: {
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    width: '90%',
    alignItems: 'center',
  },
  coverBtnConfirm: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  coverBackButton: {
    alignItems: 'center',
    textAlign: 'center',
    height: 60,
    width: '100%',
  },
  circleIcon: {
    position: 'absolute',
    top: -5,
    right: -5,
    color: theme.palette.success.main,
  },
  coverTotalPayment: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    marginBottom: theme.spacing(0.2),
  },
  coverTextMethod: {
    backgroundColor: 'white',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  coverPaymentMethod: {
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: theme.spacing(3),
  },
  coverTextOvo: {
    marginTop: theme.spacing(0.2),
    padding: theme.spacing(3),
    backgroundColor: 'white',
  },
  toolbar: {
    height: '100vh',
  },
  iconButton: {
    position: 'absolute',
    left: theme.spacing(1),
    width: 50,
  },
});

const StripePayment = () => {
  const history = useHistory();
  const classes = useStyles();
  const bigSizeScreen = useMediaQuery('(min-width:600px)');
  const { t } = useTranslation();
  const { getAuthState, fetchStripe, getColorApp } = useAuthContext();

  const [clientSecret, setClientSecret] = useState('');
  const total = history.location.state.data;

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
    style: {
      base: {
        backgroundColor: 'red',
        height: 100,
      },
    },
  };

  useEffect(() => {
    fetchStripe({ grand_total: total });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getAuthState('clientSecreet')) {
      setClientSecret(getAuthState('clientSecreet'));
    }
    // eslint-disable-next-line
  }, [getAuthState('clientSecreet')]);

  return (
    <Dialog
      open={true}
      fullScreen={true}
      maxWidth={false}
      classes={{
        paper: `${classes.paper} ${classes.modalFullScreen} ${bigSizeScreen && classes.limitSize
          }`,
        paperWidthFalse: classes.paperWidthFalse,
      }}>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        className={classes.toolbar}
        style={{ backgroundColor: getColorApp()?.backgroundColor }}>
        <Box className={classes.innerTitle}>
          <Box
            display="flex"
            flexDirection="row"
            boxShadow={3}
            bgcolor="primary.main"
            color="text.secondary"
            className={classes.coverBackButton}>
            <ButtonBase className={classes.iconButton}>
              <ChevronLeft
                style={{ width: 32, height: 32 }}
                onClick={() => {
                  history.goBack();
                }}
              />
            </ButtonBase>
            <Typography className={classes.title} variant="h5">
              {t('payment').toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Box>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          )}
          {getAuthState('loadingModal') && <LoadingModal />}
        </Box>
      </Box>
    </Dialog>
  );
};
export default StripePayment;
