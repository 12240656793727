import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useMediaQuery,
  ButtonBase,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'styles/theme';
import { Cancel } from '@material-ui/icons';
import { useAuthContext } from 'context/AuthContext';

const useStyles = makeStyles({
  paper: {
    width: `95% !important`,
    marginLeft: 0,
    marginRight: 0,
    overflowY: 'visible',
  },
  modalFullScreen: {
    width: '100% !important',
  },
  limitSize: {
    maxWidth: `${theme.containerWidth}px !important`,
  },
  paperWidthFalse: {
    maxWidth: '100%',
  },
  modalTitle: {
    textAlign: 'center',
    '& h2': {
      fontWeight: 'bold',
    },
  },
  marginCloseButton: {
    paddingTop: theme.spacing(5),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

const ReDialog = ({
  closeModal,
  title,
  titleStyle,
  content,
  fullScreen,
  floatingTitle,
  scrollType,
  useCloseButton,
  dialogStyle,
  contentStyle,
  customTitleStyle,
}) => {
  const classes = useStyles();
  const { getColorApp } = useAuthContext();
  const bigSizeScreen = useMediaQuery('(min-width:600px)');

  const FloatingBox = ({ children }) => (
    <>{floatingTitle ? <Box boxShadow={3}>{children}</Box> : children}</>
  );

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      scroll={scrollType || 'body'}
      maxWidth={false}
      fullScreen={fullScreen}
      className={dialogStyle}
      fullWidth
      classes={{
        paper: `${classes.paper} ${fullScreen && classes.modalFullScreen} 
        ${bigSizeScreen && classes.limitSize}`,
        paperWidthFalse: classes.paperWidthFalse,
      }}
    >
      {useCloseButton && (
        <Box className={classes.closeButton}>
          <ButtonBase onClick={closeModal}>
            <Cancel fontSize="large" color="primary" />
          </ButtonBase>
        </Box>
      )}
      <FloatingBox>
        <DialogTitle
          style={{ color: getColorApp()?.secondaryFontColor, ...customTitleStyle, }}
          className={`${classes.modalTitle} ${titleStyle} ${useCloseButton && classes.marginCloseButton
            }`}>
          {title}
        </DialogTitle>
      </FloatingBox>
      <DialogContent
        style={{ paddingBottom: theme.spacing(10), ...contentStyle }}>
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default ReDialog;