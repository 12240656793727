import {
  Box,
  ButtonBase,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ReDialog from 'components/ReDialog';
import React, { useEffect, useState } from 'react';
import theme from 'styles/theme';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/AuthContext';
import { convertCurrency } from 'functions/convertNumber';
import RePackageItem from 'components/RePackageItem';
import LoadingModal from 'components/LoadingModal';

const useStyles = makeStyles({
  modalTitle: {
    height: 60,
    padding: `0 ${theme.spacing(2)}px`,
  },
  detailToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
  },
  iconButton: {
    padding: theme.spacing(1),
    width: 65,
    justifyContent: 'flex-start',
  },
  buttonBase: {
    position: 'absolute',
    left: theme.spacing(3),
  },
  calculationText: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.7,
  },
  contentStyle: {
    display: 'block',
    paddingTop: theme.spacing(1),
  },

  buttonConfirm: {
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    width: '100%',
  },
});

const initialState = {
  table_name: '',
  transaction_items: [],
  status: '',
  grand_total: 0,
  taxes: [],
  sub_total: 0,
  discounts: [],
  loading: false,
  payment_status: '',
};

const DetailTransactionPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState(initialState);
  const { getOutletInfo, getColorApp } = useAuthContext();

  useEffect(() => {
    const data = history.location.state.data;
    setState(data);
    // eslint-disable-next-line
  }, []);

  return (
    <ReDialog
      fullScreen={true}
      closeModal={() => { }}
      floatingTitle={true}
      titleStyle={classes.modalTitle}
      contentStyle={{ color: getColorApp()?.secondaryFontColor }}
      scrollType={'paper'}
      title={
        <Box className={classes.detailToolbar}>
          <ButtonBase
            className={classes.iconButton}
            onClick={() => history.goBack()}>
            <ChevronLeft style={{ width: 32, height: 32 }} />
          </ButtonBase>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', display: 'flex', flexGrow: 1 }}>
            {t('detailTransaction').toUpperCase()}
          </Typography>
        </Box>
      }
      content={
        <Grid container className={classes.contentStyle}>
          {state && (
            <>
              <Grid item style={{ textAlign: 'center' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('table')} : {state.table_name}
                </Typography>
              </Grid>
              <hr style={{ borderTop: '1px dashed black' }} />

              <>
                {state.transaction_items &&
                  state.transaction_items.length > 0 &&
                  state.transaction_items.map((item) => (
                    <>
                      <Grid item style={{ display: 'flex', flex: 1 }}>
                        <Box className={classes.calculationText}>
                          {`${item.quantity} x ${item.plu_name}`}
                        </Box>
                        <Box style={{ flex: 0.1 }}>
                          {JSON.parse(getOutletInfo()).currencySymbol}
                        </Box>
                        <Box style={{ flex: 0.2, textAlign: 'right' }}>
                          {convertCurrency(item.quantity * item.amount, false)}
                        </Box>
                      </Grid>

                      {/* PREP ITEM */}
                      {Array.isArray(item.prep_items) &&
                        item.prep_items.map((prep) => (
                          <Grid item style={{ display: 'flex', flex: 1 }}>
                            <Box className={classes.calculationText}>
                              <Box style={{ marginLeft: theme.spacing(3) }}>
                                {`* ${prep.quantity} x ${prep.plu_name}`}
                              </Box>
                            </Box>
                            <Box style={{ flex: 0.1 }}>
                              {(prep.amount || 0) * (prep.quantity || 0) !== 0
                                ? JSON.parse(getOutletInfo()).currencySymbol
                                : ''}
                            </Box>
                            <Box style={{ flex: 0.2, textAlign: 'right' }}>
                              {`${(prep.amount || 0) * (prep.quantity || 0) !== 0
                                ? convertCurrency(prep.amount * prep.quantity, false)
                                : ''
                                }`}
                            </Box>
                          </Grid>
                        ))}

                      {/* DISCOUNT */}
                      {Array.isArray(item.discounts) &&
                        item.discounts.map((discount) => (
                          <Grid item style={{ display: 'flex', flex: 1 }}>
                            <Box className={classes.calculationText}>
                              <Box style={{ marginLeft: theme.spacing(3) }}>
                                {`- ${discount.discount_name}`}
                              </Box>
                            </Box>
                            <Box style={{ flex: 0.1 }}>
                              {JSON.parse(getOutletInfo()).currencySymbol}
                            </Box>
                            <Box style={{ flex: 0.2, textAlign: 'right' }}>
                              {convertCurrency(discount.amount, false)}
                            </Box>
                          </Grid>
                        ))}

                      {/* PACKAGE */}
                      {item.type === 'package' &&
                        Array.isArray(item.sub_product) &&
                        item.sub_product.length && (
                          <RePackageItem item={item} />
                        )}

                      {/* MODIFIER */}
                      {Array.isArray(item.note) &&
                        item.note.map((note, index) => (
                          <Grid item style={{ display: 'flex', flex: 1 }}>
                            <Box className={classes.calculationText}>
                              <Box style={{ marginLeft: theme.spacing(3) }}>
                                {`+ ${note}`}
                              </Box>
                            </Box>
                            <Box style={{ display: 'flex', flex: 0.3 }} />
                          </Grid>
                        ))}
                    </>
                  ))}
                <hr style={{ borderTop: '1px dashed black' }} />
              </>

              {state.discounts &&
                state.discounts.length > 0 &&
                state.discounts.map((discount) => (
                  <>
                    <Grid item style={{ display: 'flex', flex: 1 }}>
                      <Box className={classes.calculationText}>SUB TOTAL</Box>
                      <Box style={{ flex: 0.1 }}>
                        {JSON.parse(getOutletInfo()).currencySymbol}
                      </Box>
                      <Box style={{ flex: 0.2, textAlign: 'right' }}>
                        {convertCurrency(state.sub_total + discount.amount, false)}
                      </Box>
                    </Grid>
                    <Grid item style={{ display: 'flex', flex: 1 }}>
                      <Box className={classes.calculationText}>
                        {discount.discount_name}
                      </Box>
                      <Box style={{ flex: 0.1 }}>
                        {JSON.parse(getOutletInfo()).currencySymbol}
                      </Box>
                      <Box style={{ flex: 0.2, textAlign: 'right' }}>
                        {convertCurrency(discount.amount, false)}
                      </Box>
                    </Grid>
                    <hr style={{ borderTop: '1px dashed black' }} />
                  </>
                ))}
              <Grid item style={{ display: 'flex', flex: 1 }}>
                <Box className={classes.calculationText}>SUB TOTAL</Box>
                <Box style={{ flex: 0.1 }}>
                  {JSON.parse(getOutletInfo()).currencySymbol}
                </Box>
                <Box style={{ flex: 0.2, textAlign: 'right' }}>
                  {convertCurrency(state.sub_total || 0, false)}
                </Box>
              </Grid>
              {state.taxes &&
                state.taxes.map((item) => (
                  <Grid item style={{ display: 'flex', flex: 1 }}>
                    <Box className={classes.calculationText}>{item.title}</Box>
                    <Box style={{ flex: 0.1 }}>
                      {JSON.parse(getOutletInfo()).currencySymbol}
                    </Box>
                    <Box style={{ flex: 0.2, textAlign: 'right' }}>
                      {convertCurrency(item.value, false)}
                    </Box>
                  </Grid>
                ))}
              <hr style={{ borderTop: '1px dashed black' }} />
              <Grid
                item
                style={{ display: 'flex', flex: 1, fontWeight: 'bold' }}>
                <Box className={classes.calculationText}>TOTAL</Box>
                <Box style={{ flex: 0.1 }}>
                  {JSON.parse(getOutletInfo()).currencySymbol}
                </Box>
                <Box style={{ flex: 0.2, textAlign: 'right' }}>
                  {convertCurrency(state.grand_total || 0, false)}
                </Box>
              </Grid>
              {/* {state.payment_status && state.payment_status === 'PENDING' && (
                <Grid item>
                  <Button
                    className={classes.buttonConfirm}
                    onClick={handleBtnConfirm}
                    type="confirm"
                    title="Sync Transaction"
                  />
                </Grid>
              )} */}
              {state.loading && <LoadingModal />}
            </>
          )}
        </Grid>
      }
    />
  );
};

export default DetailTransactionPage;
