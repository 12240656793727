import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown } from '@material-ui/icons';

const ReDropdownLang = ({ id }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    if (value.constructor.name !== 'Class') {
      i18n.changeLanguage(value);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      {/* Button Material UI */}
      <Button
        id={id}
        onClick={handleClick}
        variant="contained"
        size="small"
        style={{ minWidth: 45 }}>
        {i18n.language.toUpperCase()} <KeyboardArrowDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose('id');
          }}>
          ID
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose('en');
          }}>
          EN
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ReDropdownLang;