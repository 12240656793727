import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Badge, useMediaQuery } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { convertCurrency } from 'functions/convertNumber';
import { useGeneralContext } from 'context/GeneralContext';
import theme from 'styles/theme';
import HomeIcon from '@material-ui/icons/Home';
import { useAuthContext } from 'context/AuthContext';

const useStyles = makeStyles({
  buttonContainer: {
    position: 'fixed',
    bottom: theme.spacing(2),
    maxWidth: `${theme.containerWidth}px`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonBigScreenPosition: {
    left: `calc(50% - (${theme.containerWidth}px/2))`,
  },
  button: {
    height: 50,
    maxWidth: theme.containerWidth,
    textTransform: 'none',
    width: '100%',
  },
  badge: {
    height: 15,
    minWidth: 15,
    fontSize: 10,
    lineHeight: 0.3,
    padding: '0 4px',
    backgroundColor: theme.palette.success.main,
  },
  typeSales: {
    fontSize: '0.75rem !important',
    paddingRight: theme.spacing(2),
  },
  innerButton: {
    justifyContent: 'space-between',
  },
  endIconStyle: {
    alignItems: 'center',
  },
});

const ReFloatingStaticButton = ({
  totalPrice,
  badgeCount,
  onClick,
  onClickBtnTenant,
}) => {
  const classes = useStyles();
  const { getTypeSalesName } = useGeneralContext();
  const { getColorApp } = useAuthContext();
  const bigSizeScreen = useMediaQuery('(min-width:600px)');

  return (
    <Grid
      container
      className={`${classes.buttonContainer} ${bigSizeScreen && classes.buttonBigScreenPosition}`}
      style={{ backgroundColor: 'transparent' }}>
      <Grid item xs={5} style={{ paddingRight: theme.spacing(2) }}>
        {/* Button Material UI */}
        <Button
          onClick={onClickBtnTenant}
          variant="contained"
          size="large"
          startIcon={<HomeIcon style={{ color: getColorApp()?.primaryFontColor }} />}
          style={{ height: 50, width: '100%', backgroundColor: getColorApp()?.secondaryThemeColor }}>
          <Typography style={{ color: getColorApp()?.primaryFontColor }}>
            Tenant
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={7}>
        {/* Button Material UI */}
        <Button
          onClick={badgeCount > 0 && onClick}
          variant="contained"
          size="large"
          style={{ backgroundColor: getColorApp()?.secondaryThemeColor }}
          endIcon={
            <>
              <Typography variant="caption" className={classes.typeSales} style={{ color: getColorApp()?.primaryFontColor }}>
                {getTypeSalesName()}
              </Typography>
              <Badge
                badgeContent={badgeCount}
                classes={{ badge: classes.badge }}>
                <ShoppingCart
                  style={{
                    fontSize: 24,
                    color: getColorApp()?.primaryFontColor,
                  }}
                />
              </Badge>
            </>
          }
          classes={{
            label: classes.innerButton,
            endIcon: classes.endIconStyle,
          }}
          className={classes.button}>
          <Typography style={{ color: getColorApp()?.primaryFontColor }}>
            {convertCurrency(totalPrice)}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReFloatingStaticButton;
