import React, { useState } from 'react';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useCartContext } from 'context/CartContext';
import theme from 'styles/theme';
import ReDialog from '../../components/ReDialog';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useAuthContext } from 'context/AuthContext';
import QrCodeDialog from './QrCodeDialog';

const useStyles = makeStyles({
  titleStyle: {
    paddingBottom: 0,
  },
  customButton: {
    marginTop: 20,
    fontWeight: 'bold',
    width: '80%',
    left: `calc(50% - 80%/2)`,
  },
  button: {
    width: '200px',
    fontWeight: 'bold',
  },
  confirmText: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
});

const SuccessConfirm = ({ classes, t, getColorApp }) => {
  const { forceReset } = useCartContext();
  const { getSetting } = useAuthContext();

  const handleConfirmBtn = () => {
    forceReset();
  };

  return (
    <ReDialog
      title={
        <CheckCircle
          style={{ color: theme.palette.success.main, fontSize: '3rem' }}
        />
      }
      titleStyle={classes.titleStyle}
      customTitleStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
      contentStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
      content={
        <Grid container direction="column" spacing={8}>
          <Grid item>
            {getSetting().orderMethod === 'waiter_order' && (
              <Typography variant="h4" style={{ textAlign: 'center', color: getColorApp()?.primaryFontColor }}>
                {t('thankyou')}
              </Typography>
            )}

            <Typography style={{ textAlign: 'center', color: getColorApp()?.primaryFontColor }}>
              {getSetting().orderMethod === 'waiter_order'
                ? t('waiterOrderComplete')
                : t('orderComplete')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleConfirmBtn}
              className={classes.customButton}
              size="large"
              type="confirm"
              title="OK"
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const ConfirmDialog = ({
  titleDialog,
  messageDialog,
  closeModal,
  confirmTransaction,
  closeQRcode,
  setting,
  getColorApp,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = useState(false);
  const [isButtonClick, setButtonClick] = useState(false);
  const [openQRcode, setOpenQRcode] = useState({ visible: false, data: null });

  const handleCloseQRcode = () => {
    setOpenQRcode({ visble: false, data: null });
    closeQRcode();
  };

  return (
    <>
      <ReDialog
        title={titleDialog || t('confirmOrder').toUpperCase()}
        customTitleStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
        contentStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
        content={
          <Grid container direction="column" spacing={8}>
            <Grid item>
              <Typography className={classes.confirmText} style={{ color: getColorApp()?.primaryFontColor }}>
                {messageDialog !== undefined && messageDialog !== ''
                  ? messageDialog
                  : setting &&
                    setting.confirmOrderText !== undefined &&
                    setting.confirmOrderText !== '' &&
                    setting.orderMethod !== 'payment_order'
                    ? setting.confirmOrderText
                    : t('confirmOrderMessage')}
              </Typography>
            </Grid>
            {isButtonClick ? (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                <CircularProgress style={{ color: getColorApp()?.secondaryThemeColor }} size={50} />
              </Grid>
            ) : (
              <Grid container direction="row">
                <Grid item style={{ width: '50%' }}>
                  <Button
                    onClick={closeModal}
                    className={classes.customButton}
                    size="large"
                    type="cancel"
                    title={`${t('cancel')}`}
                  />
                </Grid>
                <Grid item style={{ width: '50%' }}>
                  <Button
                    onClick={async () => {
                      setButtonClick(true);
                      let confirmTrans = await confirmTransaction();
                      const transaction = JSON.parse(confirmTrans);
                      if (transaction.status === 'success') {
                        setConfirmed(true);

                        if (
                          setting.orderMethod === 'quick_order' ||
                          setting.orderMethod === 'tenant_order'
                        ) {
                          setOpenQRcode({
                            visible: true,
                            data: transaction.parameter,
                          });
                        }
                      } else {
                        setButtonClick(false);
                      }
                    }}
                    className={classes.customButton}
                    size="large"
                    type="confirm"
                    title={`${t('confirm')}`}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        }
      />
      {setting.orderMethod === 'quick_order' ||
        setting.orderMethod === 'tenant_order'
        ? confirmed &&
        openQRcode.visible && (
          <QrCodeDialog
            closeModal={() => handleCloseQRcode()}
            data={openQRcode.data}
          />
        )
        : confirmed && <SuccessConfirm classes={classes} t={t} getColorApp={getColorApp()} />}
    </>
  );
};

export default ConfirmDialog;
