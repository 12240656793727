const localStorageNames = {
  SPLASH: 'splash',
  LOGO: 'logo',
  CREDENTIAL: 'creds',
  OUTLET: 'outlet_information',
  USER: 'user_information',
  ORDERCATEGORY: 'order_category',
  TENANTS: 'list_tenant',
  PRODUCTS: 'list_product',
  CART_ITEMS: 'cart_items',
  PAYMENT_ITEMS: 'payment_items',
  THEME_COLOR: 'theme_color',
  SETTING: 'setting',
  FILLED_FEEDBACK: 'filled_feedback',
  TRANSACTIONS_ID: 'transactions_id',
  VIEW_BILL_ID: 'view_bill_id',
  PAYMENT_METHOD: 'payment_method',
  IS_VIEW_BILL: 'is_view_bill',
  TENANT_ID: 'tenant_id',
  CATEGORIES: 'list_category',
  PRODUCTS_BY_CATEGORY: 'list_product_by_category',
};

export default localStorageNames;
