import { makeStyles } from '@material-ui/core/styles';
import theme from 'styles/theme';

const useStyles = makeStyles({
  modalTitle: {
    height: 60,
    padding: `0 ${theme.spacing(2)}px`,
  },
  innerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
  },
  iconButton: {
    padding: theme.spacing(1),
    width: 65,
    justifyContent: 'flex-start',
  },
  removeButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '95%',
    margin: '12px auto',
  },
  innerContentContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      4,
    )}px`,
  },
  borderingGrid: {
    borderBottom: '1px solid #e4dada',
  },
  typeSales: {
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
  },
  typeSalesTitle: {
    display: 'inline-block',
    minWidth: '60%',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 4,
    boxShadow: '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
  cartItemContainer: {
    padding: `${theme.spacing(3)}px 0`,
  },
  cartItem: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,
  },
  footer: {
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px 0`,
  },
  customButton: {
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    width: '90%',
  },
  modifierText: {
    // ATAS PERMINTAAN PAK HENDRO, WARNA INI CUSTOM
    color: '#484848',
  },
  expandButtonModifier: {
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    marginBottom: theme.spacing(2),
  },
  textWrapper: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  buttonQtyCart: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1)
  },
  buttonQtyPrepItem: {
    width: 125,
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

export default useStyles;
