import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/AuthContext';

const useStyles = makeStyles((theme) => ({
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const RePackageItem = ({ subProduct }) => {
  const classes = useStyles();
  const { getColorApp } = useAuthContext();
  const { t } = useTranslation();

  return (
    <Grid>
      <Accordion expanded={true}>
        <AccordionSummary
          classes={{
            root: classes.headingTitle,
            content: classes.headingContent,
          }}
          style={{ backgroundColor: getColorApp()?.accordionSuccessColor, color: getColorApp()?.secondaryFontColor }}
        >
          <Typography variant="subtitle2" className={classes.textWrapper}>
            {t('detailPackage')}
          </Typography>
        </AccordionSummary>
        {Array.isArray(subProduct) && subProduct.map((product) => (
          <AccordionDetails
            key={product.uuid_product}
            style={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}>
            <Grid container direction="row" justify={'space-between'}>
              <Grid item>
                <Typography variant="caption">{`${product.plu_name}`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  {`x ${product.quantity}`}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        ))}
      </Accordion>
    </Grid>
  );
};

export default RePackageItem;