import React, { useEffect } from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import GeneralProvider from 'context/GeneralContext';
import Routes from './Routes.js';
import theme from 'styles/theme';
import packageJson from '../package.json';

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'PRODUCTION') {
      document.title = `${process.env.REACT_APP_ENV} - ${document.title}`;
      console.log(`environment : ${process.env.REACT_APP_ENV}`);
      console.log(
        `cdn path : ${process.env[`REACT_APP_CDN_URL_${process.env.REACT_APP_ENV}`]
        }`,
      );
      console.log(`version code : ${packageJson.version}`);
    }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <GeneralProvider>
            <Routes />
          </GeneralProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
