import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReDialog from './ReDialog';
import { useTranslation } from 'react-i18next';
import Button from './Button';

const useStyles = makeStyles({
  titleStyle: {
    paddingBottom: 0,
  },
  customButton: {
    marginTop: 20,
    fontWeight: 'bold',
    width: '80%',
    left: `calc(50% - 80%/2)`,
  },
});

const ReOutletClosed = ({ closeModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ReDialog
      title={t('restaurantClosed')}
      titleStyle={classes.titleStyle}
      content={
        <Grid container direction="column" spacing={8}>
          <Grid item>
            <Typography style={{ textAlign: 'center' }}>
              {t('closedMessage')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => closeModal()}
              className={classes.customButton}
              size="large"
              type="confirm"
              title="OK"
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default ReOutletClosed;