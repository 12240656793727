import React from 'react';
import { Link } from 'react-scroll';

const ReHasLinkScroll = ({ children, to, onClick, onScroll }) => {
  return (
    <Link
      to={`${to}`}
      spy={true}
      smooth={true}
      offset={-100}
      duration={200}
      isDynamic={true}
      onSetActive={onScroll ? () => onScroll() : () => {}}
      onClick={onClick ? () => onClick() : () => {}}>
      {children}
    </Link>
  );
};

export default ReHasLinkScroll;