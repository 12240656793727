import { getPriceBySellBand } from './getItemPriceBySellBand';

export const sumTotalPriceWithPrepItem = (data, qty, sellBandId) => {
  let tempQty = qty > 0 ? qty : 1;
  let sumPrepItemPrice = 0;
  if (data.is_variant) {
    const variantChecked = data.variant_item.filter((data) => data.checked);

    if (variantChecked && variantChecked.length > 0) {
      if (
        variantChecked[0].prep_group &&
        variantChecked[0].prep_group.length > 0
      ) {
        for (let i = 0; i < variantChecked[0].prep_group.length; i++) {
          let prepItemPrice = 0;
          if (
            variantChecked[0].prep_group[i].prep_item &&
            variantChecked[0].prep_group[i].prep_item.length > 0
          ) {
            for (
              let j = 0;
              j < variantChecked[0].prep_group[i].prep_item.length;
              j++
            ) {
              let getPrepItemPrice = getPriceBySellBand(
                variantChecked[0].prep_group[i].prep_item[j],
                sellBandId,
              );

              if (
                variantChecked[0].prep_group[i].prep_item[j].checked &&
                getPrepItemPrice > 0
              ) {
                prepItemPrice +=
                  getPrepItemPrice *
                  (variantChecked[0].prep_group[i].prep_item[j].quantity > 0
                    ? variantChecked[0].prep_group[i].prep_item[j].quantity
                    : 1);
              }
            }
          }
          sumPrepItemPrice += prepItemPrice;
        }
      }

      sumPrepItemPrice += getPriceBySellBand(variantChecked[0], sellBandId);
    }
  } else {
    if (data.prep_group && data.prep_group.length > 0) {
      for (let i = 0; i < data.prep_group.length; i++) {
        let prepItemPrice = 0;
        if (
          data.prep_group[i].prep_item &&
          data.prep_group[i].prep_item.length > 0
        ) {
          for (let j = 0; j < data.prep_group[i].prep_item.length; j++) {
            let getPrepItemPrice = getPriceBySellBand(
              data.prep_group[i].prep_item[j],
              sellBandId,
            );

            if (
              data.prep_group[i].prep_item[j].checked &&
              getPrepItemPrice > 0
            ) {
              prepItemPrice +=
                getPrepItemPrice *
                (data.prep_group[i].prep_item[j].quantity > 0
                  ? data.prep_group[i].prep_item[j].quantity
                  : 1);
            }
          }
        }
        sumPrepItemPrice += prepItemPrice;
      }
    }
    sumPrepItemPrice += getPriceBySellBand(data, sellBandId);
  }

  return sumPrepItemPrice * tempQty;
};

export const sumTotalPriceWithPrepItemV2 = (data, qty) => {
  let tempQty = qty > 0 ? qty : 1;
  let sumPrepItemPrice = 0;
  if (data.prep_items && data.prep_items.length > 0) {
    for (let i = 0; i < data.prep_items.length; i++) {
      const prepItemPrice =
        data.prep_items[i].baseQuantity * data.prep_items[i].amount * tempQty;

      sumPrepItemPrice += prepItemPrice;
    }
  }

  return sumPrepItemPrice;
};
