import defaultSplash from 'assets/images/default_splash.png';
import defaultLogo from 'assets/images/default_logo.png';
import localStorageNames from 'data/localStorageNames';

const getSplashName = localStorage.getItem(localStorageNames.SPLASH);
const getLogoName = localStorage.getItem(localStorageNames.LOGO);

export const getSplashUrlPath = () => {
  return getSplashName;
};

export const getLogoUrlPath = () => {
  return getLogoName;
};

export const getDefaultSplash = () => {
  return defaultSplash;
};

export const getDefaultLogo = () => {
  return defaultLogo;
};