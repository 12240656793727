import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import AuthProvider from 'context/AuthContext';
import ReLoading from 'components/ReLoading';
import theme from 'styles/theme';
import DetailTransactionPage from 'pages/DetailTransactionPage';
import StripePayment from 'pages/checkout/StripePayment';
import RealmProvider from 'context/RealmContext';

const HomePage = lazy(() => import(`./pages/home/HomePage`));
const SplashScreen = lazy(() => import(`./pages/SplashScreen`));
const LoginPage = lazy(() => import(`./pages/LoginPage`));
const OrderCategory = lazy(() => import(`./pages/OrderCategory`));
const MissingTokenPage = lazy(() => import(`./pages/MissingTokenPage`));
const ErrorPage = lazy(() => import(`./pages/ErrorPage`));
const SearchProductPage = lazy(() => import(`./pages/SearchProductPage`));
const FeedbackPage = lazy(() => import(`./pages/feedback/FeedbackPage`));
const CategoryPage = lazy(() => import(`./pages/CategoryPage`));
const ViewBillPage = lazy(() => import(`./pages/ViewBillPage`));
const ViewTransactionPage = lazy(() => import(`./pages/ViewTransactionPage`));
const PaymentPage = lazy(() => import(`./pages/checkout/PaymentPage`));
const SuccessPaymentPage = lazy(() => import(`./pages/SuccessPaymentPage`));
const SuggestionPage = lazy(() => import(`./pages/SuggestionPage`));
const TenantPage = lazy(() => import(`./pages/TenantPage`));

const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    minHeight: '100vh',
  },
});

const Routes = () => {
  const bigSizeScreen = useMediaQuery('(min-width:600px)');
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={
        bigSizeScreen
          ? { maxWidth: theme.containerWidth }
          : { width: '100%', minWidth: 300 }
      }>
      <Suspense fallback={<ReLoading />}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <AuthProvider>
                <ReLoading {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/splash"
            render={(props) => (
              <AuthProvider>
                <SplashScreen {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/login"
            render={(props) => (
              <AuthProvider>
                <LoginPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/menu-catalogue"
            render={(props) => (
              <AuthProvider>
                <HomePage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/order-category"
            render={(props) => (
              <AuthProvider>
                <OrderCategory {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/search-product"
            render={(props) => (
              <AuthProvider>
                <SearchProductPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/feedback"
            render={(props) => (
              <AuthProvider>
                <FeedbackPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/category"
            render={(props) => (
              <AuthProvider>
                <CategoryPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/view-bill"
            render={(props) => (
              <AuthProvider>
                <ViewBillPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/suggestion"
            render={(props) => (
              <AuthProvider>
                <SuggestionPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/view-transaction"
            render={(props) => (
              <AuthProvider>
                <ViewTransactionPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/detail-transaction"
            render={(props) => (
              <AuthProvider>
                <DetailTransactionPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/payment"
            render={(props) => (
              <AuthProvider>
                <PaymentPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/payment-stripe"
            render={(props) => (
              <AuthProvider>
                <StripePayment {...props} />
              </AuthProvider>
            )}
          />
          <Route
            path="/success-payment/:type"
            render={(props) => (
              <RealmProvider>
                <SuccessPaymentPage {...props} />
              </RealmProvider>
            )}
          />
          <Route
            exact
            path="/tenant-page"
            render={(props) => (
              <AuthProvider>
                <TenantPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/error-page"
            render={(props) => <ErrorPage {...props} />}
          />
          <Route
            exact
            path="/session-expired"
            render={(props) => <MissingTokenPage type="expired" {...props} />}
          />
          <Route
            exact
            path="/invalid-token"
            render={(props) => <MissingTokenPage type="invalid" {...props} />}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
