import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const OrderedPackage = ({ classes, subProduct, qtyProduct, getColorApp }) => {
  return (
    <div>
      {Array.isArray(subProduct) && subProduct.map((item, index) => {
        return (
          <Grid
            key={item.uuid_product || index}
            container
            direction="row"
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item style={{ flex: 4 }}>
              <Typography
                variant="caption"
                className={`${classes.modifierText} ${classes.textWrapper}`}
                style={{ width: '95%', color: getColorApp()?.primaryFontColor }}>
                {`+${item.plu_name}`}
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1, textAlign: 'right' }}>
              <Typography variant="caption" className={classes.modifierText} style={{ color: getColorApp()?.primaryFontColor }}>
                x {item.quantity * qtyProduct}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default OrderedPackage;
