import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#757575',
    },
    error: {
      main: '#B00020',
    },
    warning: {
      main: '#FF9100',
    },
    info: {
      main: '#0096C7',
    },
    success: {
      main: '#02A328',
    },
  },
  typography: {
    fontFamily: ['"Helvetica Neue"'].join(','),
  },
  spacing: 4,
  containerWidth: 450,
});

theme = responsiveFontSizes(theme);

export default theme;
