import React, { useState, useContext } from 'react';
import { useAuthContext } from 'context/AuthContext';
import localStorageNames from 'data/localStorageNames';
import moment from 'moment-timezone';

// realm
import * as Realm from 'realm-web';
const REALM_APP_ID =
  process.env[`REACT_APP_REALM_CONFIGURATION_${process.env.REACT_APP_ENV}`];
const realmApp = new Realm.App({ id: REALM_APP_ID });

export const RealmContext = React.createContext([{}, () => { }]);

const RealmProvider = ({ children }) => {
  const [state, setState] = useState({
    transaction: {},
    realmCredential: {},
  });

  const {
    getCredential,
    getOutletInfo,
    getTableName,
    getOutletId,
    getOrderMethod,
    getSetting,
  } = useAuthContext();

  const setLocalState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getLocalState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const loginRealm = async () => {
    try {
      if (getCredential() && getOutletId()) {
        const credentials = Realm.Credentials.function({
          outlet_id: getOutletId(),
        });
        const user = await realmApp.logIn(credentials);
        setLocalState({ realmCredential: user });

        return user;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getTransactionsId = () => {
    return JSON.parse(localStorage.getItem(localStorageNames.TRANSACTIONS_ID));
  };

  const saveTransactionsId = async (transactionId) => {
    let tempTransactionsId = [];
    if (getTransactionsId()) {
      tempTransactionsId = getTransactionsId();
    }
    tempTransactionsId.push(transactionId);
    localStorage.setItem(
      localStorageNames.TRANSACTIONS_ID,
      JSON.stringify(tempTransactionsId),
    );
  };

  const hitRealmTransaction = async (payloadData) => {
    if (getCredential()) {
      let user = await loginRealm();
      const result = await user.callFunction('clientTransaction', {
        method: 'POST',
        data: {
          ...payloadData,
        },
      });
      // result transactionId
      await saveTransactionsId(result);
      return result;
    }
  };

  const hitRealmTransaction2 = async (payloadData) => {
    if (getCredential()) {
      let user = await loginRealm();
      const result = await user.callFunction('clientTransactionV2', {
        method: 'POST',
        data: {
          ...payloadData,
        },
      });
      // result transactionId
      return result;
    }
  };

  const hitRealmTransactionStripe = async (data) => {
    const credentials = Realm.Credentials.function({
      outlet_id: data.outletId,
    });
    const user = await realmApp.logIn(credentials);
    const result = await user.callFunction('stripePaymentSucces', {
      method: 'POST',
      _id: data.id,
      SubFunctionID: parseInt(data.subFunctionID),
    });
    // result true || false
    return result;
  };

  const hitRealmTable = async (filter, payloadData) => {
    if (getCredential()) {
      await loginRealm();

      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
      const tableCollection = mongodb.db('RDO').collection('tables');

      let result = await tableCollection.updateOne(
        { ...filter },
        { ...payloadData },
        { upsert: true },
      );
      return result;
    }
  };

  const listenTransaction = async (callback) => {
    let outletInfo = JSON.parse(getOutletInfo());
    if (getCredential()) {
      await loginRealm();

      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
      const transCollection = mongodb.db('RDO').collection('transactions');

      let filterTransaction = {
        filter: {
          'fullDocument.status': { $nin: ['close', 'expired'] },
          'fullDocument.table_id': outletInfo.tableId,
          'fullDocument.type_bill': getOrderMethod(),
        },
      };

      if (getSetting().orderMethod === 'payment_order') {
        const viewBillId = localStorage.getItem(localStorageNames.VIEW_BILL_ID);
        filterTransaction.filter['fullDocument._id'] = new Realm.BSON.ObjectId(
          viewBillId,
        );
      }

      for await (const change of transCollection.watch({ filterTransaction })) {
        let dataChanges = change.fullDocument;
        let queryChangedData = await queryTransaction({
          _id: new Realm.BSON.ObjectId(dataChanges._id.toString()),
        });

        callback(queryChangedData[0]);
      }
    }
  };

  const queryTransaction = async (filter) => {
    let outletInfo = JSON.parse(getOutletInfo());
    if (getCredential() && outletInfo) {
      await loginRealm();

      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');

      const transCollection = mongodb.db('RDO').collection('transactions');
      let rawData = await transCollection.aggregate([
        { $match: { ...filter, table_id: outletInfo.tableId } },
        {
          $lookup: {
            from: 'transaction_items',
            let: { tItems: '$transaction_items' },
            pipeline: [
              {
                $match: {
                  $expr: { $in: ['$_id', { $ifNull: ['$$tItems', []] }] },
                },
              },
              {
                $lookup: {
                  from: 'transaction_prep_items',
                  localField: 'prep_items',
                  foreignField: '_id',
                  as: 'prep_items',
                },
              },
              {
                $lookup: {
                  from: 'transaction_discounts',
                  localField: 'discounts',
                  foreignField: '_id',
                  as: 'discounts',
                },
              },
            ],
            as: 'transaction_items',
          },
        },
        {
          $lookup: {
            from: 'transaction_taxes',
            localField: 'taxes',
            foreignField: '_id',
            as: 'taxes',
          },
        },
        {
          $lookup: {
            from: 'transaction_discounts',
            localField: 'discounts',
            foreignField: '_id',
            as: 'discounts',
          },
        },
        {
          $project: {
            _id: 1,
            outlet: 1,
            table_id: 1,
            table_name: 1,
            grand_total: 1,
            sub_total: 1,
            status: 1,
            is_split: 1,
            transaction_items: {
              _id: 1,
              plu_number: 1,
              plu_name: 1,
              amount: 1,
              quantity: 1,
              sales_type: 1,
              note: 1,
              type: 1,
              prep_items: 1,
              discounts: {
                _id: 1,
                amount: 1,
                discount_name: 1,
                discount_percent: 1,
                functionId: 1,
                type: 1,
              },
            },
            taxes: {
              _id: 1,
              title: 1,
              value: 1,
              TTax: 1,
            },
            discounts: {
              _id: 1,
              amount: 1,
              discount_name: 1,
              discount_percent: 1,
              functionId: 1,
              type: 1,
            },
          },
        },
      ]);
      return rawData;
    }
  };

  const queryTransactionsHistory = async () => {
    let outletInfo = JSON.parse(getOutletInfo());
    let transactionsId = getTransactionsId();
    if (getCredential() && outletInfo && transactionsId) {
      await loginRealm();

      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');

      const mapTransId = transactionsId.map((data) => {
        return new Realm.BSON.ObjectId(data);
      });

      const filterById = {
        $in: mapTransId,
      };

      const transCollection = mongodb.db('RDO').collection('transactions');
      let rawData = await transCollection.aggregate([
        { $match: { _id: filterById, table_id: outletInfo.tableId } },
        {
          $lookup: {
            from: 'transaction_items',
            let: { tItems: '$transaction_items' },
            pipeline: [
              {
                $match: {
                  $expr: { $in: ['$_id', { $ifNull: ['$$tItems', []] }] },
                },
              },
              {
                $lookup: {
                  from: 'transaction_prep_items',
                  localField: 'prep_items',
                  foreignField: '_id',
                  as: 'prep_items',
                },
              },
              {
                $lookup: {
                  from: 'transaction_discounts',
                  localField: 'discounts',
                  foreignField: '_id',
                  as: 'discounts',
                },
              },
            ],
            as: 'transaction_items',
          },
        },
        {
          $lookup: {
            from: 'transaction_taxes',
            localField: 'taxes',
            foreignField: '_id',
            as: 'taxes',
          },
        },
        {
          $lookup: {
            from: 'transaction_discounts',
            localField: 'discounts',
            foreignField: '_id',
            as: 'discounts',
          },
        },
        {
          $project: {
            _id: 1,
            outlet: 1,
            table_id: 1,
            table_name: 1,
            grand_total: 1,
            sub_total: 1,
            status: 1,
            is_split: 1,
            updatedAt: 1,
            payment_status: 1,
            transaction_items: {
              _id: 1,
              plu_number: 1,
              plu_name: 1,
              amount: 1,
              quantity: 1,
              sales_type: 1,
              note: 1,
              type: 1,
              prep_items: 1,
              discounts: {
                _id: 1,
                amount: 1,
                discount_name: 1,
                discount_percent: 1,
                functionId: 1,
                type: 1,
              },
            },
            taxes: {
              _id: 1,
              title: 1,
              value: 1,
              TTax: 1,
            },
            discounts: {
              _id: 1,
              amount: 1,
              discount_name: 1,
              discount_percent: 1,
              functionId: 1,
              type: 1,
            },
          },
        },
      ]);
      return rawData;
    }
  };

  const checkCurrentTable = async () => {
    let outletInfo = JSON.parse(getOutletInfo());

    if (getCredential() && outletInfo) {
      await loginRealm();

      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
      const tableCollection = mongodb.db('RDO').collection('tables');
      let rawData = await tableCollection.findOne({
        table_id: outletInfo.tableId,
      });
      return rawData;
    }
  };

  const checkTableStatus = async () => {
    let outletInfo = JSON.parse(getOutletInfo());
    if (
      outletInfo &&
      (getOrderMethod() === 'normal_order' ||
        getOrderMethod() === 'waiter_order')
    ) {
      await hitRealmTable(
        {
          _partition: getOutletId(),
          table_id: outletInfo.tableId,
        },
        {
          _partition: getOutletId(),
          table_id: outletInfo.tableId,
          table_name: getTableName(),
          status: 'check',
        },
      );
    }
  };

  const reOpenStatusTransaction = async () => {
    await loginRealm();
    let oldTransaction = await queryTransaction({
      status: { $nin: ['close', 'expired'] },
      type_bill: getOrderMethod(),
    });
    let oldIds = oldTransaction.map((trans) => trans._id);

    // JIKA ADA TRANSAKSI, REOPEN TRANSAKSI, JIKA TIDAK ADA, HANYA CEK TABLE STATUS NYA
    if (Array.isArray(oldIds) && oldIds.length > 0) {
      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
      const transCollection = mongodb.db('RDO').collection('transactions');

      let result = await transCollection.updateMany(
        { _id: { $in: oldIds } },
        {
          $set: {
            status: 'open',
          },
        },
        { upsert: false },
      );
      // Ketika View Bill Jangan Lupa Tambah Counter di Log View Bill
      if (result) {
        const logViewBillCollection = mongodb
          .db('RDO')
          .collection('log_viewbill');

        let table = await checkCurrentTable();
        if (table) {
          let payload = {
            _partition: getOutletId(),
            table_id: table._id,
            date: moment(new Date()).format('DD-MM-YYYY'),
          };

          await logViewBillCollection.updateOne(
            {
              ...payload,
            },
            {
              $set: {
                ...payload,
              },
              $inc: { counter: 1 },
            },
            { upsert: true },
          );
        }
      }

      return result;
    } else {
      await checkTableStatus();
    }
  };

  const sendClientPayment = async (
    payloadData,
    transactionId,
    subFunctionId,
  ) => {
    if (getCredential()) {
      let user = await loginRealm();
      const result = await user.callFunction('clientPayment', {
        method: 'POST',
        data: {
          ...payloadData,
        },
        id_transaction: transactionId,
        SubFunctionID: parseInt(subFunctionId),
      });

      if (result) {
        saveTransactionsId(transactionId);
      }
      return result;
    }
  };

  const syncXenditStatusPayment = async (idTransaction) => {
    if (getCredential()) {
      let user = await loginRealm();
      const value = await user.callFunction('syncXenditStatusPayment', {
        _id: idTransaction.toString(),
      });
      return value;
    }
  };

  return (
    <RealmContext.Provider
      value={{
        getLocalState,
        setLocalState,
        hitRealmTransaction,
        hitRealmTransaction2,
        hitRealmTransactionStripe,
        queryTransaction,
        queryTransactionsHistory,
        checkTableStatus,
        checkCurrentTable,
        listenTransaction,
        reOpenStatusTransaction,
        sendClientPayment,
        getTransactionsId,
        syncXenditStatusPayment,
        saveTransactionsId,
      }}>
      {children}
    </RealmContext.Provider>
  );
};

export const useRealmContext = () => {
  const value = useContext(RealmContext);
  if (value == null) {
    throw new Error('useCartContext() called outside of a Provider?');
  }
  return value;
};

export default RealmProvider;
