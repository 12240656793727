export const getPriceBySellBand = (data, sellBandId) => {
  let price = 0;

  if (data.is_variant) {
    const variantChecked = data.variant_item.filter((data) => data.checked);
    if (variantChecked && variantChecked.length > 0) {
      price = filterSellbandPrice(variantChecked[0], sellBandId);
    } else {
      price = filterSellbandPrice(data, sellBandId);
    }
  } else {
    price = filterSellbandPrice(data, sellBandId);
  }

  return price;
};

//cek sell band pada item, jika tidak ada maka harga di set 0
const filterSellbandPrice = (data, sellBandId) => {
  let price = 0;

  if (
    data.sell_bands &&
    Array.isArray(data.sell_bands) &&
    data.sell_bands.length > 0
  ) {
    let filterSellBands = data.sell_bands.filter(
      (data) => data.sell_band_key === sellBandId,
    );

    if (Array.isArray(filterSellBands) && filterSellBands.length > 0) {
      filterSellBands.forEach((data) => {
        price = data.sell_band_price;
      });
    }
  } else {
    price = 0;
  }
  return price;
};
