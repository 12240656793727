import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  ButtonBase,
  Box,
  makeStyles,
  Input
} from '@material-ui/core';
import { convertCurrency } from 'functions/convertNumber';
import ReDialog from 'components/ReDialog';
import theme from 'styles/theme';
import { ChevronLeft, Delete } from '@material-ui/icons';
import { useGeneralContext } from 'context/GeneralContext';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/AuthContext';
import { useRealmContext } from 'context/RealmContext';
import CartItem from './CartItem';
import useStyles from './checkout.styles';
import Button from 'components/Button';
import { useCartContext } from 'context/CartContext';
import LoadingModal from 'components/LoadingModal';

const CheckoutPage = ({
  closeModal,
  cartItem = [],
  realmCartItem = [],
  changeItemQty,
  totalPrice,
  totalQty,
  clearCart,
  removeItemInCart,
  onConfirm,
  onViewBill,
  changePrepItemQuantity,
  changeRealmPrepItemQuantity,
}) => {
  const classes = useStyles();
  const { getTypeSalesName, getSellBandId } = useGeneralContext();
  const { getTableName, getSetting, getColorApp } = useAuthContext();
  const { checkTableStatus } = useRealmContext();
  const { getContextState } = useCartContext();
  const { t } = useTranslation();
  const [parameter, setParameter] = useState('');
  const [isErrorTextField, setErrorTextField] = useState(false);

  const customStyle = makeStyles({
    textField: {
      backgroundColor: '#FFFFFF',
      minWidth: '60%',
      border: 0,
      height: 40,
      borderRadius: 4,
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      boxSizing: 'border-box',
    },
    input: {
      height: 40,
      padding: 0,
      margin: 0,
      textAlign: 'center'
    },
    hintError: {
      color: theme.palette.error.main,
      margin: theme.spacing(1),
    },
  });
  const customClasses = customStyle();

  const handleConfirm = (orderMethod) => {
    if (getSetting().isStore && getSetting().isQRStoreParameter && !parameter) {
      setErrorTextField(true);
      return;
    }
    onConfirm(orderMethod, parameter);
  };

  const handleIput = (event) => {
    setErrorTextField(false);
    setParameter(event.target.value);
  };

  useEffect(() => {
    checkTableStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <ReDialog
      fullScreen={true}
      closeModal={closeModal}
      titleStyle={classes.modalTitle}
      customTitleStyle={{ backgroundColor: getColorApp()?.primaryThemeColor, color: getColorApp()?.secondaryFontColor }}
      contentStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor, padding: `${theme.spacing(2)}px 0` }}
      floatingTitle={true}
      scrollType={'paper'}
      title={
        <Box className={classes.innerTitle} style={{ backgroundColor: getColorApp()?.primaryThemeColor }}>
          <ButtonBase className={classes.iconButton} onClick={closeModal}>
            <ChevronLeft style={{ width: 32, height: 32, color: getColorApp()?.secondaryFontColor }} />
          </ButtonBase>
          <Typography variant="h6" style={{ fontWeight: 'bold', color: getColorApp()?.secondaryFontColor }}>
            {t('orderList').toUpperCase()}
          </Typography>
          <ButtonBase
            onClick={() => {
              clearCart();
            }}
            className={`${classes.iconButton} ${classes.removeButton}`}>
            <Delete style={{ fontSize: 18, color: getColorApp()?.secondaryFontColor }} />
            <Typography style={{ textAlign: 'center', fontSize: 10, color: getColorApp()?.secondaryFontColor }}>
              {t('voidAll')}
            </Typography>
          </ButtonBase>
        </Box>
      }
      content={
        <Paper className={classes.contentContainer} elevation={3} style={{ backgroundColor: getColorApp()?.backgroundColor }}>
          <Box className={classes.innerContentContainer} style={{ backgroundColor: getColorApp()?.backgroundColor }}>
            <Grid className={`${classes.typeSales} `}>
              {getSetting() && getSetting().isStore ? (
                <div>
                  {getSetting().isQRStoreParameter && (
                    <div>
                      <Input
                        className={customClasses.textField}
                        classes={{ input: customClasses.input }}
                        style={{ color: getColorApp()?.primaryFontColor }}
                        onChange={(event) => handleIput(event)}
                        placeholder={
                          getSetting().placeholderParameter
                            ? getSetting().placeholderParameter
                            : t('placeholderParameter')
                        }
                        inputProps={{ maxLength: 20 }}
                        disableUnderline
                      />
                      {isErrorTextField && (
                        <Typography
                          variant="caption"
                          component="div"
                          className={customClasses.hintError}>
                          *{t('helperParameter')}
                        </Typography>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.typeSalesTitle}
                  style={{ backgroundColor: getColorApp()?.secondaryThemeColor, color: getColorApp()?.primaryFontColor }}>
                  {t('table').toUpperCase()} : {getTableName()}
                </Typography>
              )}
            </Grid>
            <Grid className={`${classes.typeSales} ${classes.borderingGrid}`}>
              <Typography
                component="span"
                variant="body1"
                className={classes.typeSalesTitle}
                style={{ backgroundColor: getColorApp()?.secondaryThemeColor, color: getColorApp()?.primaryFontColor }}>
                {getTypeSalesName()}
              </Typography>
            </Grid>

            {getSetting() && getSetting().orderMethod === 'payment_order' && (
              <CartItem
                classes={classes}
                t={t}
                getSellBandId={getSellBandId}
                data={realmCartItem}
                changeItemQty={changeItemQty}
                removeItem={removeItemInCart}
                changePrepItemQuantity={changePrepItemQuantity}
                changeRealmPrepItemQuantity={changeRealmPrepItemQuantity}
                isNormalOrder={false}
                isPaymentOrder={true}
                getColorApp={getColorApp}
              />
            )}
            <CartItem
              classes={classes}
              t={t}
              getSellBandId={getSellBandId}
              data={cartItem}
              changeItemQty={changeItemQty}
              removeItem={removeItemInCart}
              changePrepItemQuantity={changePrepItemQuantity}
              changeRealmPrepItemQuantity={changeRealmPrepItemQuantity}
              isNormalOrder={true}
              isPaymentOrder={false}
              getColorApp={getColorApp}
            />

            <Grid
              container
              direction="row"
              className={`${classes.borderingGrid} ${classes.footer}`}>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: getColorApp()?.primaryFontColor }}>
                  Total Qty
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: getColorApp()?.primaryFontColor }}>
                  {totalQty}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={`${classes.borderingGrid} ${classes.footer}`}>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: getColorApp()?.primaryFontColor }}>
                  TOTAL
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: getColorApp()?.primaryFontColor }}>
                  {convertCurrency(totalPrice)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ width: '100%', textAlign: 'center' }}>
              {getSetting() && getSetting().orderMethod === 'normal_order' && (
                <Button
                  onClick={() => {
                    onConfirm(getSetting().orderMethod, null);
                  }}
                  className={classes.customButton}
                  size="large"
                  type="confirm"
                  title={t('confirmOrder')}
                />
              )}
              {getSetting() && getSetting().orderMethod === 'payment_order' && (
                <Button
                  onClick={() => {
                    onViewBill(getSetting().orderMethod);
                  }}
                  className={classes.customButton}
                  size="large"
                  type="confirm"
                  title={t('viewBill')}
                />
              )}
              {getSetting() && getSetting().orderMethod === 'waiter_order' && (
                <Button
                  onClick={() => {
                    onConfirm(getSetting().orderMethod, null);
                  }}
                  className={classes.customButton}
                  size="large"
                  type="confirm"
                  title={t('confirmOrder')}
                />
              )}
              {getSetting() && getSetting().orderMethod === 'quick_order' && (
                <Button
                  onClick={() => {
                    handleConfirm(getSetting().orderMethod);
                  }}
                  className={classes.customButton}
                  size="large"
                  type="confirm"
                  title={t('confirmOrder')}
                />
              )}

              {getSetting() && getSetting().orderMethod === 'tenant_order' && (
                <Button
                  onClick={() => {
                    handleConfirm(getSetting().orderMethod);
                  }}
                  className={classes.customButton}
                  size="large"
                  type="confirm"
                  title={t('confirmOrder')}
                />
              )}
              {getContextState('loadingModal') && <LoadingModal />}
            </Grid>
          </Box>
        </Paper>
      }
    />
  );
};

export default CheckoutPage;
