import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'styles/theme';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useAuthContext } from 'context/AuthContext';

const useStyles = makeStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
});

const LoadingModal = () => {
  const classes = useStyles();
  const { getColorApp } = useAuthContext();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress style={{ color: getColorApp()?.secondaryThemeColor }} size={50} />
    </Backdrop>
  );
};

export default LoadingModal;