import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  container: {
    textAlign: 'center',
    width: '100%'
  }
});

const ReFooter = ({ textColor }) => {
  const clases = useStyle();

  return (
    <Box className={clases.container}>
      <Typography
        color="textSecondary"
        variant="subtitle2"
        style={textColor && { color: textColor }}>
        Powered by
        <b>{` Raptor Digital Ordering`}</b>
      </Typography>
    </Box>
  );
};

export default ReFooter;