import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import noimage from 'assets/images/noimage.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  generalImage: {
    objectFit: 'cover',
  },
});

const ReLazyImage = ({ url, style }) => {
  const classes = useStyles();
  return (
    <LazyLoadImage
      wrapperClassName={classes.generalImage}
      style={{ width: '100%', objectFit: 'cover', ...style }}
      alt=""
      effect="blur"
      src={url ? `${url}` : noimage}
    />
  );
};

export default ReLazyImage;