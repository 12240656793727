import localStorageNames from 'data/localStorageNames';

export function convertCurrency(number, withCurrency = true) {
  const outlet = JSON.parse(localStorage.getItem(localStorageNames.OUTLET));

  let result = new Intl.NumberFormat(outlet.localeCode, {
    style: 'currency',
    currency: outlet.currency,
  }).format(outlet.localeCode === 'id-ID' ? Math.round(number) : number);
  if (outlet.localeCode === 'id-ID') {
    let split = result.split(',');
    result = split[0];
  }
  if (!withCurrency) {
    result = result.replace(outlet.currencySymbol, '').trim();
  }
  return result;
}