import React, { useState, useContext } from 'react';
import localStorageNames from 'data/localStorageNames';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const GeneralContext = React.createContext([{}, () => { }]);


const GeneralProvider = ({ children }) => {
  const [state, setState] = useState({});

  const setContextState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContextState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const openDrawer = () => {
    setContextState({
      drawer: true,
    });
  };

  const closeDrawer = () => {
    setContextState({
      drawer: false,
    });
  };

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const getLocalStorage = (key) => {
    return localStorage.getItem(key);
  };

  const setUserLocalStorage = (data) => {
    setLocalStorage(localStorageNames.USER, data);
  };

  const setOrderCategoryLocalStorage = (data) => {
    setLocalStorage(localStorageNames.ORDERCATEGORY, data);
  };

  const getSellBandId = () => {
    let sellBandId = getLocalStorage(localStorageNames.ORDERCATEGORY);
    sellBandId = JSON.parse(sellBandId);
    return sellBandId ? sellBandId.sell_band : 0;
  };

  const getTypeSalesName = () => {
    let typesales = getLocalStorage(localStorageNames.ORDERCATEGORY);
    typesales = JSON.parse(typesales);
    return typesales ? typesales.title : '';
  };

  const toastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const toastError = (text) => {
    toast.error(text, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <GeneralContext.Provider
      value={{
        getContextState,
        setContextState,
        toastSuccess,
        toastError,
        openDrawer,
        closeDrawer,
        getLocalStorage,
        setLocalStorage,
        setUserLocalStorage,
        setOrderCategoryLocalStorage,
        getTypeSalesName,
        getSellBandId,
      }}>
      {children}
      <ToastContainer theme="colored" />
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => {
  const value = useContext(GeneralContext);
  if (value == null) {
    throw new Error('useGeneralContext() called outside of a Provider?');
  }
  return value;
};

export default GeneralProvider;
