import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Box, Button, makeStyles } from '@material-ui/core';
import LoadingModal from 'components/LoadingModal';
import { useGeneralContext } from 'context/GeneralContext';
import { useCartContext } from 'context/CartContext';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  buttonConfirm: {
    fontWeight: 'bold',
  },

  paymentForm: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 150,
    width: '100%',
  },
});

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const { toastSuccess, toastError } = useGeneralContext();
  const { clearOrderCart, setRealmCartItemLocalStorage } = useCartContext();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          toastSuccess('Payment succeeded!');
          break;
        case 'processing':
          toastSuccess('Your payment is processing.');
          break;
        case 'requires_payment_method':
          toastError(
            'Your payment was not successful, please try again.',
          );
          break;
        default:
          toastError('Something went wrong.');
          break;
      }
    });
    // eslint-disable-next-line
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    clearOrderCart();
    setRealmCartItemLocalStorage([]);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env[`REACT_APP_PAYMENT_SUCCESS_${process.env.REACT_APP_ENV}`] + '/stripe',
        // return_url: 'http://localhost:3000/success-payment/stripe', //ini untuk running di local
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      toastError(error.message);
    }

    setIsLoading(false);
  };

  return (
    <Box className={classes.container}>
      <form className={classes.paymentForm} onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <Button
          disabled={isLoading || !stripe || !elements}
          type="submit"
          variant="contained"
          className={classes.buttonConfirm}>
          Pay Now
        </Button>
        {isLoading && <LoadingModal />}
      </form>
    </Box>
  );
}
